<template>
  <div>
    <FilterSanzioniEconomato
      @getEconomatoList="getEconomatoListResetPage"
      @resetFilters="resetFilters"
    />
    <TableSanzioniEconomato
      @getEconomatoList="getEconomatoList"
      @resetFilters="resetFilters"
    />
  </div>
</template>

<script>
import { defineComponent, onMounted, computed, getCurrentInstance } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import FilterSanzioniEconomato from "@/components/components-fit/gestione/economato/FiltriSanzioniEconomato.vue";
import TableSanzioniEconomato from "@/components/components-fit/gestione/economato/TableSanzioniEconomato.vue";

export default defineComponent({
  name: "sanzioni-economato",
  components: {
    FilterSanzioniEconomato,
    TableSanzioniEconomato,
  },
  props: {},
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Economato");
    });
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const anno = computed(() => store.getters.stagioneSelected);

    const idComitatoRegionale = computed(
      () => store.getters.idComitatoRegionaleSanzioni
    );
    const idOrganoSanzioniEconomato = computed(
      () => store.getters.idOrganoSanzioniEconomato
    );
    const startDateEmissione = computed(
      () => store.getters.dataEmissioneDaSanzioni
    );
    const endDateEmissione = computed(
      () => store.getters.dataEmissioneASanzioni
    );
    const startDateApprovazione = computed(
      () => store.getters.dataApprovazioneDaSanzioni
    );
    const endDateApprovazione = computed(
      () => store.getters.dataApprovazioneASanzioni
    );
    const idSocieta = computed(() => store.getters.idSocietaSanzioniEconomato);

    const rowsToSkip = computed(
      () => store.getters.rowsToSkipSanzioniEconomato
    );
    const fetchRows = computed(() => store.getters.fetchRowsSanzioniEconomato);
    const sortColumn = computed(
      () => store.getters.sortColumnSanzioniEconomato
    );
    const sortOrder = computed(() => store.getters.sortOrderSanzioniEconomato);

    const getEconomatoList = () => {
      const localKeys = {
        stagione: anno.value,
        id_Comitato: idComitatoRegionale.value,
        id_organo: idOrganoSanzioniEconomato.value,
        data_Ins_Da: startDateEmissione.value,
        data_Ins_A: endDateEmissione.value,
        data_Appr_Da: startDateApprovazione.value,
        data_Appr_A: endDateApprovazione.value,
        id_Societa: idSocieta.value,
        rowstoskip: rowsToSkip.value,
        fetchrows: fetchRows.value,
        sortcolumn: sortColumn.value,
        sortorder: sortOrder.value,
      };
      store.dispatch("setStoreListData", {
        keys: localKeys,
        apiLink: globalApi.ECONOMATO_SANZIONI_LIST,
        itemName: "economato_sanzioni_list",
      });
    };

    const resetFilters = () => {
      store.commit("resetFiltersSanzioni");
      getEconomatoList();
    };

    const loaded = computed(() =>
      store.getters.getStateFromName("loadedeconomato_sanzioni_list")
    );
    const getEconomatoListResetPage = () => {
      if (!loaded.value) return;
      store.commit("resetRowsToSkipSanzioniEconomato");
      getEconomatoList();
    };
    getEconomatoList();

    return {
      getEconomatoList,
      resetFilters,
      getEconomatoListResetPage,
    };
  },
});
</script>
